import React from 'react';
import List from '../../general/List';
import withDataHOC from '../../dataProvider/withDataHOC';
import {GLOBAL_DATA} from '../../../constants/globalData';
import {EventTypes} from '../../../constants/propTypesDefinitions';
import FlagEdit from './FlagEdit';
import FlagRemove from './FlagRemove';

/**
 * @dusan
 */

class FlagList extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            selectedId: null,
        }
    }

    static propTypes = {
        [GLOBAL_DATA.FLAGS]: EventTypes.isRequired,
    };

    onSelect = (flagId) => {
        this.setState({
            selectedId: flagId,
        })
    };

    render() {
        const {selectedId} = this.state;
        const flags = this.props[GLOBAL_DATA.FLAGS];
        return <List
                values={flags}
                onItemClick={(value) => {
                    this.onSelect(value.id);
                }}
                selectedValueId={selectedId}
            >
                {flags.map((obj) => {
                    return <div key={obj.id} className="d-flex flex-wrap align-items-center">
                        {obj.name}
                        {obj.id == selectedId ? [
                            <FlagEdit
                                key="edit"
                                flag={obj}
                            />,
                            <FlagRemove
                                key="remove"
                                flag={obj}
                            />
                        ] : null}
                    </div>
                })}
        </List>;
    }

}

export default withDataHOC([GLOBAL_DATA.FLAGS])(FlagList);